<template>
    <v-container class="document">
        <v-layout column>
            <v-flex mt-3 v-for="(item,name) in docs" :key="name">
                <h3 class="fs-1">{{name}}</h3>
                <v-layout wrap style="margin-left: -16px;margin-right: -16px">
                    <v-flex pa-3 md4 sm6 xs12 v-for="(v,k) in item" :key="k">
                        <a :href="v" target="_blank">
                            <div class="card-wrapper">
                                <div class="img-box ">
                                    <img src="@/assets/images/document.png" alt="document">
                                </div>
                                <div class="text-box" style="text-align: center">
                                    {{k}}
                                </div>
                            </div>
                        </a>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

    export default {
        name: "document",
        data() {
            return {
                docs: {
                    Database: {
                        MySQL: 'https://www.sqlclusters.com/docs/mysql/',
                        CockroachDB: 'https://www.cclusters.com/docs/cockroachdb/',
                        MariaDB: 'https://www.maclusters.com/docs/mariadb/',
                        'SQL Server': 'https://www.msclusters.com/docs/sqlserver/',
                        Redis: 'https://www.rclusters.com/docs/redis/',
                        Oracle: 'https://www.oclusters.com/docs/oracle/',
                        Elasticsearch: 'https://www.esclusters.com/docs/elasticsearch/',
                        PostgreSQL: 'https://www.pgsclusters.com/docs/postgresql/',
                        Cassandra: 'https://www.caclusters.com/docs/cassandra/'
                    },
                    Runtimes: {
                        PhpClusters: 'https://www.cloudclusters.io/docs/runtime/',
                        JavaClusters: 'https://www.cloudclusters.io/docs/runtime/',
                        PythonClusters: 'https://www.cloudclusters.io/docs/runtime/',
                        AspnetClusters: 'https://www.cloudclusters.io/docs/runtime/',
                        NodejsClusters: 'https://www.cloudclusters.io/docs/runtime/'
                    },
                    Ecom: {
                        Magento: 'https://www.mgtclusters.com/docs/magento/',
                        OpenCart: 'https://docs.cloudclusters.io/opencart/',

                    },
                    "Message Queue": {
                        Kafka: 'https://www.kaclusters.com/docs/kafka/',
                    },
                    CMS: {
                        WordPress: 'https://www.wp-clusters.com/docs/wordpress/',
                        Drupal: 'https://www.dp-clusters.com/docs/drupal/',
                    },
                    CRM: {
                        Odoo: 'https://www.odclusters.com/docs/odoo/',

                    },
                    Education:{
                        Moodle: 'https://www.moclusters.com/docs/moodle/',
                    },
                    Operations:{
                        Prometheus: 'https://www.pclusters.com/docs/prometheus/',
                    },
                    // General: 'https://www.cloudclusters.io/docs/general/',
                }
            }
        },
        created() {
            this.$SDK.track({
                pageName: 'Documentation',
            })
        }
    }
</script>

<style lang="scss">
    .document {
        a {
            text-decoration: none !important;

        }

        .row {
            margin-left: -45px;
            margin-right: -45px;
        }

        .per {
            padding: 30px 45px;
        }

        .card-wrapper {
            display: flex;
            flex-direction: row;
            border-radius: 5px;
            transition: all 0.3s ease;

            &:hover {
                transform: translateY(-10px);
            }

            .img-box {
                flex-shrink: 1;
                background-color: #ffffff;
                padding: 15px;
                border-radius: 8px 0 0 8px;
            }

            .text-box {
                background-color: #ebf0ff;
                flex-grow: 1;
                border-radius: 0 8px 8px 0;
                font-size: 24px;
                line-height: 100px;
            }
        }
    }

</style>
