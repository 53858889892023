var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "document" },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        _vm._l(_vm.docs, function(item, name) {
          return _c(
            "v-flex",
            { key: name, attrs: { "mt-3": "" } },
            [
              _c("h3", { staticClass: "fs-1" }, [_vm._v(_vm._s(name))]),
              _c(
                "v-layout",
                {
                  staticStyle: {
                    "margin-left": "-16px",
                    "margin-right": "-16px"
                  },
                  attrs: { wrap: "" }
                },
                _vm._l(item, function(v, k) {
                  return _c(
                    "v-flex",
                    {
                      key: k,
                      attrs: { "pa-3": "", md4: "", sm6: "", xs12: "" }
                    },
                    [
                      _c("a", { attrs: { href: v, target: "_blank" } }, [
                        _c("div", { staticClass: "card-wrapper" }, [
                          _c("div", { staticClass: "img-box " }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/document.png"),
                                alt: "document"
                              }
                            })
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "text-box",
                              staticStyle: { "text-align": "center" }
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(k) +
                                  "\n                            "
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }